import React from 'react';
import Sidebar from "react-sidebar";
import SidebarMenu from './SidebarMenu';
import { Row, Col, Button } from 'antd';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { logout } from "../Controllers/Auth";

const mql = window.matchMedia(`(min-width: 800px)`);

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docked: mql.matches,
      open: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetOpen(open) {
    this.setState({ open });
  }

  mediaQueryChanged() {
    this.setState({
      docked: mql.matches,
      open: false
    });
  }

  toggleOpen(ev) {
    this.setState({ open: !this.state.open });
    if (ev) {
      ev.preventDefault();
    }
  }

  menuButtonClick(ev) {
    ev.preventDefault();
    this.onSetOpen(!this.state.sidebarOpen);
  }

  render() {
    return (
      <>
        <Sidebar
          sidebar={<SidebarMenu style={styles.menuContent} />}
          open={this.state.open}
          docked={this.state.docked}
          onSetOpen={this.onSetOpen}
          transitions={false}
          shadow={false}
        >
          <Row align='middle' justify='start' style={styles.fixedHeader} >
            <Col span={12}>
              {!this.state.docked && (
                <>
                  <a onClick={this.toggleOpen} style={styles.contentHeaderMenu}>
                    <MenuOutlined />
                  </a>
                  <img style={{ height: '45px' }} src={require('../Assets/logo-white.png')} />
                </>
              )}
            </Col>
            <Col span={12}>
              <Row align='middle' justify='end'>
                <Col style={styles.clickable} onClick={() => logout()} >
                  <Row justify='center'>
                    <LogoutOutlined style={{ fontSize: '24px', color: 'white' }} />
                  </Row>
                  <Row justify='center'>
                    <a style={{ fontSize: '12px', textDecoration: 'none', color: 'white' }}>
                      ESCI
                    </a>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={styles.wrapperContent}>
            {this.props.children}
          </div>
        </Sidebar>
      </>
    );
  }
}

const HEADER_HEIGHT = '64px';
const styles = {
  fixedHeader: {
    position: 'fixed',
    width: '100%',
    height: HEADER_HEIGHT,
    backgroundColor: '#516375',
    top: 0,
    left: 0,
    padding: '0 20px',
    zIndex: 1
  },
  wrapperContent: {
    padding: '38px 47px',
    marginTop: HEADER_HEIGHT
  },
  menuContent: {
    marginTop: HEADER_HEIGHT
  },
  contentHeaderMenu: {
    textDecoration: "none",
    padding: 8,
    fontSize: '18px',
    color: 'white',
    marginRight: '8px'
  },
  clickable: {
    cursor: 'pointer'
  }
}

export default Wrapper;