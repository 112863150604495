import React from 'react';
import { Row, Col, Button, Select } from 'antd';
import Settings from '../Config/Settings';

class Homepage extends React.Component {
  render() {
    return (
      <div className="login-background">
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15px 0' }}>
          <img src={require('../Assets/logo-white.png')} style={{ height: 100, marginTop: 35, marginBottom: 39 }} alt="logo" />
        </div>
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col span={24}>
            <h1 className="home-benvenuto">Benvenuto su Garzone!</h1>
            <h1 className="home-subtitle">Accedi al pannello del tuo comune!</h1>
          </Col>
          <Col span={24}>
            <h3 style={{ color: Settings.colors.white, fontWeight: 'semibold', marginTop: 40 }}>
              Garzone è il modo migliore di far ripartire il tuo business!
						</h3>
          </Col>
        </Row>
        <Row justify="center" gutter={20} style={{ textAlign: 'center', marginTop: 30 }}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <p className="home-caption" style={{ fontSize: 14, color: Settings.colors.lightGrey }}>
              Sei già registrato?
						</p>
            <Button
              size="large"
              block
              type="default"
              ghost
              style={{ marginBottom: 20 }}
              onClick={() => this.props.history.push('/accedi')}
            >
              Accedi al pannello
						</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Homepage;
