import React from "react";
import { connect } from "react-redux";

import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  notification,
  Row,
  Tag,
  Typography,
  Modal,
} from "antd";

import ComuneDescriptionLabel from "../Components/ComuneDescriptionLabel";
import Wrapper from "../Components/Wrapper";
import PageHeader from "../Components/PageHeader";
import Loading from "../Components/Loading";

import { get_comune } from "../Controllers/Comune";
import {
  logout,
  requestResetPassword,
  resendEmailVerification,
  updateUserEmail,
  updateUserMailchimp,
} from "../Controllers/Auth";
import Settings from "../Config/Settings";
import { EditOutlined } from "@ant-design/icons";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comune: {},
      loaded: false,

      drawer_email: false,
      new_email: "",
      new_email_conf: "",
      mail_modal: false,
    };
  }

  _updateUserPassword = async () => {
    try {
      this.setState({ loaded: false });
      const password = await requestResetPassword(this.props.auth.user.email);
      this.setState({ password_sent: true, loaded: true });
      notification.success({
        message: "Mail di reset password inviata correttamente!",
        description: "Controlla la tua casella mail per resettare la password.",
      });
    } catch (error) {
      notification.destroy();
      notification.error({
        message: "Errore",
        description: "Si è verificato un errore. Riprova.",
      });
    }
  };

  _updateUserEmail = async () => {
    const { new_email, new_email_conf } = this.state;
    //Validation
    if (new_email !== "" && new_email != null) {
      if (new_email !== new_email_conf) {
        this.setState({ email_errors: "Le email non corrispondono" });
      } else {
        try {
          this.setState({ loaded: false });
          console.log(new_email);
          const vecchia_mail = this.props.auth.user.email;
          console.log("old:", vecchia_mail, " - new: ", new_email);
          const res = await updateUserMailchimp(vecchia_mail, new_email);
          const user = await updateUserEmail(new_email);
          //console.log('aggiornamento email', user);
          this.setState({
            mail_modal: true,
            drawer_email: false,
            new_email: "",
            new_email_conf: "",
          });
          // notification.success({
          //   message: "Mail modificata corretamente!",
          //   description: "Ora dovrai usare la mail nuova quando loggherai.",
          // })
        } catch (e) {
          if (e.code == "auth/invalid-email") {
            this.setState({ new_email: "", new_email_conf: "" });
            notification.error({
              message: "Email non valida",
              description:
                "L'email che hai inserito non è valida. Inseriscine un'altra.",
            });
          } else if (e.code == "auth/requires-recent-login") {
            notification.warn({
              message: "Login richiesto",
              description:
                "Per effettuare il cambio mail, devi rifare l'accesso a Garzone. Verrai reindirizzato alla pagina di login.",
            });
            setTimeout(() => {
              logout();
              this.props.history.push("/accedi");
            }, 5000);
          } else if (e.code == "auth/email-already-in-use") {
            this.setState({ new_email: "", new_email_conf: "" });
            notification.error({
              message: "Email già in uso",
              description:
                "L'email inserita è già stata usata da un'altro utente. Inseriscine un'altra.",
            });
          } else {
            this.setState({ drawer_email: false });
            notification.error({
              message: "Errore",
              description:
                "Si è verificato un errore durante l'aggiornamento dell'indirizzo email",
            });
          }
        } finally {
          this.setState({ loaded: true });
        }
      }
    } else {
      this.setState({ email_errors: "Inserisci un indirizzo email" });
    }
  };

  _loader = async () => {
    let user = this.props.auth.user;
    try {
      const { data } = await get_comune(user.id_comune);
      this.setState({ comune: data, loaded: true });
    } catch (e) {
      notification.error({
        message: "Errore!",
        description:
          "Errore nell'esecuzione della richiesta, riprova più tardi",
      });
      console.log("Errore", e);
    }
  };

  componentDidMount() {
    this._loader();
  }

  render() {
    const {
      comune,
      loaded,
      drawer_email,
      new_email,
      new_email_conf,
      email_errors,
      drawer_pw,
      password_sent,
    } = this.state;

    return (
      <>
        <Wrapper>
          <PageHeader
            title="Il tuo account"
            description="Qui trovi i dati del profilo del comune. Contatta Garzone per eventuali modifiche."
          />
          <Divider />
          <Row justify="start" gutter={[15, 15]}>
            <Col md={8} sm={24} xs={24}>
              <img
                src={comune.logo}
                alt="Logo Comune"
                style={{ width: "100%" }}
              />
            </Col>
            <Col md={16} sm={24} xs={24}>
              <Row justify="start" align="top" gutter={[15, 15]}>
                <Col span={24}>
                  <Input
                    style={{ marginBottom: 10 }}
                    value={this.props.auth.user.email}
                    placeholder="Email"
                    disabled={true}
                  />
                  <Button
                    block
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() => this.setState({ drawer_email: true })}
                  >
                    Cambia email
                  </Button>
                  <Drawer
                    title="Cambia email account comune"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ drawer_email: false })}
                    visible={drawer_email}
                    footer={
                      <Row
                        justify="space-between"
                        align="middle"
                        gutter={[15, 15]}
                      >
                        <Col span={24}>
                          <Button
                            size="large"
                            block
                            onClick={() =>
                              this.setState({ drawer_email: false })
                            }
                          >
                            Annulla
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button
                            size="large"
                            block
                            onClick={this._updateUserEmail}
                            type="primary"
                          >
                            Invia
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <Row justify="center" align="middle" gutter={[15, 15]}>
                      <Col span={24}>
                        <Row justify="center" align="middle" gutter={[15, 15]}>
                          <Col span={24}>
                            <span
                              style={{
                                marginBottom: 10,
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              Nuova Mail
                            </span>
                            <Input
                              value={new_email}
                              onChange={(value) =>
                                this.setState({ new_email: value.target.value })
                              }
                              placeholder="Mail account"
                            />
                            <div
                              style={{ marginBottom: 20 }}
                              className="input-error"
                            >
                              {email_errors}
                            </div>
                            <span
                              style={{
                                marginBottom: 10,
                                color: Settings.colors.darkGrey,
                              }}
                            >
                              Ripeti Mail
                            </span>
                            <Input
                              value={new_email_conf}
                              onChange={(value) =>
                                this.setState({
                                  new_email_conf: value.target.value,
                                })
                              }
                              placeholder="Conferma Mail"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Drawer>
                </Col>
                <Col span={24}>
                  <Button
                    icon={<EditOutlined />}
                    type="primary"
                    block
                    onClick={() => this.setState({ drawer_pw: true })}
                  >
                    Cambia password
                  </Button>
                  <Drawer
                    title="Cambia password"
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ drawer_pw: false })}
                    visible={drawer_pw}
                  >
                    {password_sent ? (
                      <div>
                        L'email è stata inviata. Controlla la tua casella di
                        posta (anche nella spam) e premi il link contenuto nella
                        mail per reimpostare la password.
                      </div>
                    ) : (
                      <Row justify="center" align="top" gutter={[15, 15]}>
                        <Col span={24}>
                          <Row justify="center" align="top" gutter={[15, 15]}>
                            <Col span={24}>
                              <span style={{ color: Settings.colors.darkGrey }}>
                                Premi questo pulsante per inviare un link alla
                                tua mail e cambiare password.
                              </span>
                            </Col>
                            <Col span={24}>
                              <Button
                                onClick={this._updateUserPassword}
                                type="primary"
                                block
                                size="large"
                                style={{
                                  marginTop: 30,
                                  paddingLeft: 30,
                                  paddingRight: 30,
                                }}
                              >
                                Invia
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Drawer>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {/* Abilitato/Disabilitato */}
              <Row justify="start" gutter={[15, 15]}>
                <Col span={24}>
                  {comune.abilitato ? (
                    <Tag color="green">Abilitato</Tag>
                  ) : (
                    <Tag color="red">Disabilitato</Tag>
                  )}
                </Col>
              </Row>
              {/* Nome Comune */}
              <Row justify="start" gutter={[15, 15]}>
                <Col span={24}>
                  <Typography.Title level={3}>{comune.nome}</Typography.Title>
                </Col>
              </Row>
              {/* Dati */}
              <Row justify="start" gutter={[15, 15]}>
                <Col sm={12} xs={24}>
                  <ComuneDescriptionLabel
                    title="Telefono"
                    content={comune.telefono}
                  />
                </Col>
                <Col sm={12} xs={24}>
                  <ComuneDescriptionLabel
                    title="Partita IVA"
                    content={comune.partita_iva}
                  />
                </Col>
                <Col sm={12} xs={24}>
                  <ComuneDescriptionLabel
                    title="Indirizzo"
                    content={`${comune.indirizzo}, ${comune.civico}`}
                  />
                </Col>
                <Col sm={12} xs={24}>
                  <ComuneDescriptionLabel title="CAP" content={comune.cap} />
                </Col>

                <Col lg={24} md={24} sm={24} xs={24}>
                  <ComuneDescriptionLabel
                    title="E-Mail di contatto"
                    content={comune.email}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
        {/* Modale mail */}
        <Modal
          title="Email modificata"
          visible={this.state.mail_modal}
          closable={false}
          maskClosable={false}
          footer={
            <Button
              type="primary"
              onClick={async () => {
                await logout();
                this.props.history.push("/accedi");
              }}
            >
              OK
            </Button>
          }
        >
          L'email è stata cambiata correttamente. Premi OK per tornare al login,
          dovrai aspettare 60 secondi per richiedere una mail di conferma.
        </Modal>
        <Loading loading={!loaded} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Account);
