import React from 'react';
import { Result, Button } from 'antd';

class PaginaNonTrovata extends React.Component {
	render() {
		return (
			<Result
				status="404"
				title="Pagina non trovata"
				subTitle="Ooops, la pagina che stai cercando non esiste."
				extra={
					<Button type="primary" onClick={() => this.props.history.push('/dashboard')}>
						Torna alla home
					</Button>
				}
			/>
		);
	}
}

export default PaginaNonTrovata;
