import React from "react";

// import Auth controller functions
import { requestResetPassword } from "../Controllers/Auth";
import Settings from "../Config/Settings";
// import ant design
import { Input, Button, Alert, Row, Col } from "antd";

import CustomResult from "../Components/CustomResult";
class RecuperaPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error_message: "",
      successful_request: false,
    };
  }

  // Updates form values into the component's state
  _handleChange = (e) => {
    this.setState({ error_message: "" });
    this.setState({ [e.target.id]: e.target.value });
  };

  // Fires the reset password request when form is submitted
  _doRequestResetPassword = (e) => {
    e.preventDefault();
    this._callReset();
  };

  _callReset = async () => {
    //console.log("email:", this.state.email);
    await requestResetPassword(this.state.email)
      .then(() => {
        // email sent
        // clean error_message
        this.setState({ error_message: "" });
        // change global state
        this.setState({ successful_request: true });
      })
      .catch((e) => {
        this.setState({ error_message: "Email errata! Riprova." });
      });
  };

  render() {
    return (
      <>
        <div style={styles.loginBackground}>
          <Row justify="center" align="middle">
            <Col>
              <img
                src={require("../Assets/logo-white.png")}
                style={styles.logoStyle}
                alt="logo"
              />
            </Col>
          </Row>
          {this.state.successful_request === true ? (
            <>
              <Row justify="center" align="middle" gutter={[15, 15]}>
                <CustomResult
                  icon="success"
                  title="Email inviata!"
                  subtitle={
                    <span>
                      Abbiamo appena inviato una email a:{" "}
                      <span
                        style={{
                          color: "var(--white)",
                          fontWeight: "semibold",
                        }}
                      >
                        {this.state.email}
                      </span>{" "}
                      con le istruzioni per impostare una nuova password.
                      Controlla anche la casella “spam”.
                      <br />
                      <br />
                      Non hai ricevuto nessuna email?
                    </span>
                  }
                  button={{
                    title: "Invia",
                    route: () => this._callReset(),
                  }}
                  buttonStyle={{ marginTop: -24 }}
                />
              </Row>
              <Row justify="center" align="middle" gutter={[15, 15]}>
                <Col>
                  <span style={{ color: "var(--white)" }}>
                    Hai le credenziali?
                    <br />
                    Accedi subito al tuo account.{" "}
                  </span>

                  <a onClick={() => this.props.history.push("/accedi")}>
                    Clicca qui
                  </a>
                </Col>
              </Row>
            </>
          ) : (
            <div className="login-div">
              <h1 className="login-benvenuto">Hai dimenticato la password?</h1>
              {this.state.error_message !== "" ? (
                <Alert
                  message={this.state.error_message}
                  type="error"
                  style={{ marginBottom: "15px" }}
                />
              ) : null}
              <div className="login-dati">
                <p
                  style={{
                    textAlign: "center",
                    color: "var(--white)",
                    fontSize: 15,
                  }}
                >
                  Inserisci qui sotto la email che hai usato per registrarti,
                  <br />
                  ti invieremo le informazioni per reimpostare la password
                </p>
                <form
                  onSubmit={(e) => this._doRequestResetPassword(e)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                >
                  <Input
                    className="input-text"
                    placeholder="Email"
                    id="email"
                    value={this.state.email}
                    onChange={(e) => this._handleChange(e)}
                    style={{ width: 300, marginBottom: 20 }}
                  />
                  <Button
                    className="primaryBtn"
                    block
                    type="primary"
                    htmlType="submit"
                    style={{ width: 300, marginBottom: "15px" }}
                  >
                    Invia richiesta
                  </Button>
                </form>
                <div style={{ marginTop: 40, textAlign: "center" }}>
                  <span style={{ color: "var(--white)" }}>
                    Hai le credenziali?
                    <br />
                    Accedi subito al tuo account.{" "}
                  </span>
                  <a onClick={() => this.props.history.push("/accedi")}>
                    Clicca qui
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: "var(--white)",
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
};

export default RecuperaPassword;
