import React from 'react';

class DescriptionLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      content: props.content,
      goTo: props.goTo
    }
  }

  render() {
    return (
      <div style={styles.wrapper}>
        <span style={styles.label}>{this.state.title}: </span>
        {
          this.state.goTo
            ?
            <a href={this.state.goTo}>{this.state.content}</a>
            :
            this.state.content
        }
      </div>
    );
  }
}

const styles = {
  wrapper: {
    marginBottom: "7px",
    color: "rgba(0, 0, 0, 0.65)"
  },
  label: {
    fontWeight: 'bold',
    color: "rgba(0, 0, 0, 0.85)"
  }
}

export default DescriptionLabel;