import React from 'react';

import PrivateRoute from './Components/PrivateRoute';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { checkAuthState, getClaims } from "./Controllers/Auth";
import { successLogin, successLogout } from './Actions/AuthActions';
import { connect } from 'react-redux';

import Homepage from './Screens/Homepage';
import Login from './Screens/Login';
import RecuperaPassword from './Screens/RecuperaPassword';
import Negozi from './Screens/Negozi';
import PaginaNonTrovata from './Screens/PaginaNonTrovata';
import Dashboard from './Screens/Dashboard';
import Network from './Screens/Network';
import Account from './Screens/Account';
import ConfermaMail from './Screens/ConfermaMail';
import { Row, Col, Button } from 'antd';

// import ant design css
import "./App.less";
import "./App.css";

import { CustomerServiceOutlined, RotateLeftOutlined } from '@ant-design/icons';
import { get_comune } from './Controllers/Comune';
import NonAbilitato from './Screens/NonAbilitato';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      loading: true,
      not_verified: false,
      abilitato: false
    }
    if (process.env.REACT_APP_STAGE == "development") {
      var noIndex = document.createElement('meta');
      noIndex.setAttribute('name', 'robots');
      noIndex.content = 'noindex';
      document.getElementsByTagName('head')[0].appendChild(noIndex);
    }
  }

  _getClaims = async () => {
    try {
      let { claims } = await getClaims();

      if (claims.comune) {
        const logged_user = {
          is_logged_in: true,
          email: claims.email,
          email_verified: claims.email_verified,
          id_comune: claims.id_comune,
        };
        console.log(logged_user);
        let { data } = await get_comune(logged_user.id_comune);
        this.setState({ abilitato: data.abilitato });

        //Check if user's email is verified
        if (!logged_user.email_verified) {
          //console.log('Not verified');
          this.setState({ authed: true, loading: false, not_verified: true });
        } else {
          this.setState({ authed: true, loading: false, not_verified: false });
        }

        //Save user in global state
        this.props.successLogin(logged_user);
      } else {
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    } catch (error) {
      console.log("errore", error);
    }
  };

  //chiamato la prima volta che viene renderizzato
  componentDidMount() {
    this.authListener = checkAuthState((user) => {
      if (user) {
        this._getClaims();
      } else {
        //console.log('Non loggato');
        // change local state
        this.setState({ authed: false, loading: false });
        // change global state
        this.props.successLogout();
      }
    });
  }

  //chiamato quando viene cambiato screen
  componentWillUnmount() {
    this.authListener();
  }

  //rendering app
  render() {
    return (
      this.state.loading === true
        ?
        <Row style={{ paddingTop: 40 }}>
          <Col xs={{ span: 8, offset: 8 }} md={{ span: 2, offset: 11 }}>
            <img className="loading" src={require("./Assets/loading.gif")} />
          </Col>
        </Row>
        :
        //router
        <Router>
          <div>
            {
              process.env.REACT_APP_STAGE == "development"
                ? <div style={{ width: "150px", display: "inline-block", transform: "rotate(45deg)", background: "yellow", top: 30, right: -30, zIndex: 5000000, position: "fixed", textAlign: "center", fontSize: "10pt" }}>{process.env.REACT_APP_STAGE.toUpperCase().substring(0, 3)}</div>
                : null
            }
            <Button
              style={{ borderRadius: "25px", width: "50px", height: "50px", display: "inline-block", bottom: 10, left: 10, zIndex: 5000000, position: "fixed", textAlign: "center", fontSize: "10pt" }}
              type="primary"
              icon={<CustomerServiceOutlined />}
              onClick={() => window.location = "mailto:info@garzone.store"}
            />
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route path="/accedi" component={Login} />
              <Route path="/recupera-password" component={RecuperaPassword} />
              <Route path="/non-abilitato" component={NonAbilitato} />

              <PrivateRoute path="/conferma-mail" abilitato={this.state.abilitato} authed={this.state.authed} component={ConfermaMail} />
              <PrivateRoute path='/dashboard' abilitato={this.state.abilitato} authed={this.state.authed} notVerified={this.state.not_verified} component={Dashboard} />
              <PrivateRoute path='/negozi' abilitato={this.state.abilitato} authed={this.state.authed} notVerified={this.state.not_verified} component={Negozi} />
              <PrivateRoute path='/network' abilitato={this.state.abilitato} authed={this.state.authed} notVerified={this.state.not_verified} component={Network} />
              <PrivateRoute path='/account' abilitato={this.state.abilitato} authed={this.state.authed} notVerified={this.state.not_verified} component={Account} />

              <Route path="/404" component={PaginaNonTrovata} />
              <Route component={PaginaNonTrovata} />
            </Switch>
          </div>
        </Router>
    );
  }
}

// le key sono le actions dichiarate nei file actions
function mapDispatchToProps(dispatch) {
  return {
    successLogin: (user) => dispatch(successLogin(user)),
    successLogout: () => dispatch(successLogout())
  }
}

export default connect(null, mapDispatchToProps)(App);