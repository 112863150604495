import React, { Component } from 'react';
import { Row, Col, Typography } from "antd";
import Settings from "../Config/Settings";

const { Title } = Typography;

class Label extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { titolo, desc } = this.props;
    return (
      <Row justify='start' align='top' gutter={15}>
        <Col span={24}>
          <Title style={styles.label} level={4}>{titolo}</Title>
        </Col>
        <Col span={24}>
          <span style={styles.desc}>{desc}</span>
        </Col>
      </Row>
    );
  }
}

const styles = {
  label: {
    fontSize: '28px',
    color: 'var(--darkGrey)'
  },
  desc: {
    fontSize: 18,
    fontWeight: 'semibold',
    color: Settings.colors.grey
  }
}

export default Label;