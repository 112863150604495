import { Row, Tag } from 'antd';
import React from 'react';
import Settings from '../Config/Settings';

class ComuneDescriptionLabel extends React.Component {
  render() {
    return (
      <>
        <Row style={styles.title}>{this.props.title}</Row>
        <Row style={styles.content}>{this.props.content == '' ? <Tag color="orange">N.D.</Tag> : this.props.content}</Row>
      </>
    );
  }
}

const styles = {
  title: {
    fontWeight: 'bold',
    color: Settings.colors.darkGrey
  },
}

export default ComuneDescriptionLabel;