import React from 'react';

// import router
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends React.Component {
  is_mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      not_found: false,
      loading: true
    };
  }

  componentDidMount() {
    this.is_mounted = true;
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  render() {
    console.log(this.props.abilitato);
    return this.props.notVerified ? (
      <Redirect to={'/conferma-mail'} />
    ) : this.props.abilitato == false ? (
      <Redirect to={'non-abilitato'} />
    ) : this.props.authed == true ? (
      <Route {...this.props} />
    ) : this.props.location.pathname != '/conferma-mail' ? (
      <Redirect to={{ pathname: '/accedi', state: { from: this.props.location } }} />
    ) : (
              <Route {...this.props} />
            )
  }
}

export default PrivateRoute;
