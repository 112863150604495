export function successLogin(payload){
    return {
        type: 'SUCCESS_LOGIN',
        payload: payload
    }
}

export function successLogout(){
    return {
        type: 'SUCCESS_LOGOUT'
    }
}