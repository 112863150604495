import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';

import Settings from '../Config/Settings';

const { Title } = Typography;

class PageHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, description } = this.props;
    return (
      <Row justify='start' align='top' gutter={15}>
        <Col span={24}>
          <Title style={styles.title} level={1}>{title}</Title>
        </Col>
        <Col span={24}>
          <Title style={styles.subtitle} level={4}>{description}</Title>
        </Col>
      </Row>
    );
  }
}

const styles = {
  title: {
    color: Settings.colors.darkGrey,
  },
  subtitle: {
    color: Settings.colors.grey
  }
}


export default PageHeader;