import { Functions } from "../Config/Fire";

export async function get_negozi_comune(id_comune) {
  const negozi = Functions.httpsCallable('comune-getNegoziComune');
  return negozi({ id: id_comune });
}

export async function aggiorna_negozio(id_negozio, abilitato) {
  const negozi = Functions.httpsCallable('comune-abilitaNegozio');
  return negozi({ id: id_negozio, abilitato: abilitato });
}

export async function get_negozio(id) {
  const negozio = Functions.httpsCallable('comune-getNegozio');
  return negozio(id);
}