import React from 'react';
import Wrapper from '../Components/Wrapper';
import Label from '../Components/Label';
import { Divider } from 'antd';
import PageHeader from '../Components/PageHeader';

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <Wrapper>
          <PageHeader title='Dashboard' description='Qui trovi i dati del profilo del comune. Contatta Garzone per eventuali modifiche.' />
          <Divider />
        </Wrapper>
      </>
    );
  }
}

export default Dashboard;