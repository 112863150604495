import React from 'react';
import { Menu, Row } from 'antd';
import { DashboardOutlined, SettingOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: this.props.voices,
      navigation: this.props.navigation,
      selectedKey: ['1']
    }
  }

  _getSelectedKey = () => {
    let path = this.props.location.pathname.toLowerCase();
    let dict = { '/dashboard': '1', '/negozi': '2', '/network': '3', '/account': '4' };
    return dict[path];
  }

  handleClick = (e) => {
    this.props.history.push(e.item.props.route);
  };


  render() {
    let selectedKeys = []
    selectedKeys.push(this._getSelectedKey());
    return (
      <div style={styles.mainCointainer}>
        <Row style={styles.fixedHeader} align='middle' justify='start'>
          <img style={{ height: '45px' }} src={require('../Assets/logo-white.png')} alt="Logo" />
        </Row>
        <Row align='middle' justify='start' style={styles.menuTitle}>
          MENU'
            </Row>
        <Menu
          onClick={this.handleClick}
          style={styles.menuContent}
          defaultSelectedKeys={selectedKeys}
          mode="inline">

          <Menu.Item key="1" icon={<DashboardOutlined />} route='/dashboard'>
            Dashboard
          </Menu.Item>

          <Menu.Item key="2" icon={<ShopOutlined />} route='/negozi'>
            Negozi
          </Menu.Item>

          {/* <Menu.Item key="3" icon={<SettingOutlined />} route='/network'>
            Network
          </Menu.Item> */}

          <Menu.Item key="4" icon={<UserOutlined />} route='/account'>
            Account
          </Menu.Item>
        </Menu >
      </div>
    );
  }
}

const HEADER_HEIGHT = '64px';

const styles = {
  mainCointainer: {
    backgroundColor: "#F2F3F4",
    height: "100%",
  },
  fixedHeader: {
    position: "sticky",
    height: HEADER_HEIGHT,
    width: "100%",
    backgroundColor: "#516375",
    padding: "0 20px",
    zIndex: 100,
  },
  menuContent: {
    width: 256,
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  menuTitle: {
    width: 256,
    padding: "15px",
    fontWeight: "bold",
    color: "#516375",
    backgroundColor: "#F2F3F4",
    boxShadow: "0",
    outlineColor: "transparent",
  },
  contentHeaderMenu: {
    textDecoration: "none",
    color: "white",
    padding: 8,
    fontSize: "18px",
  }
}


export default withRouter(SidebarMenu);