import React from "react";

// import Auth controller functions
import { login, getClaims, logout } from "../Controllers/Auth";

// import ant design
import { Row, Col, Input, Button, Alert, Typography, Form } from "antd";
import CustomResult from "../Components/CustomResult";
import Settings from "../Config/Settings";
import { HomeOutlined, LoginOutlined, RightOutlined } from "@ant-design/icons";
import { get_comune } from "../Controllers/Comune";
import Loading from "../Components/Loading";

const { Title } = Typography;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error_message: "",
      successful_login: false,
      wrong_permissions: false,
      not_abilitato: false,
      loading: false,
    };
  }

  // Updates form values into the component's state
  _handleChange = (e) => {
    this.setState({ error_message: "", [e.target.id]: e.target.value });
  };

  _getClaims = async () => {
    try {
      let { claims } = await getClaims();
      console.log("user claims", claims);
      if (claims.comune) {
        let { data } = await get_comune(claims.id_comune);
        console.log(data);
        if (data.abilitato) {
          this.setState({
            error_message: "",
            successful_login: true,
          });
        } else {
          this.setState({
            error_message: "",
            not_abilitato: true,
          });
        }
      } else {
        //Se non è un comune
        this.setState({ error_message: "", wrong_permissions: true });
      }
    } catch (error) {
      console.log("errore", error);
    }
  };

  // Fires the login action when form is submitted
  _doLogin = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    try {
      const user = await login(this.state.email, this.state.password);
      await this._getClaims();
    } catch (error) {
      this.setState({ error_message: "Credenziali errate! Riprova." });
    } finally {
      this.setState({ loading: false });
    }
  };

  _destinationAfterSuccessLogin = () => {
    // check if redirect to
    let destination = "/dashboard";
    if (this.props.location.state) {
      destination = this.props.location.state.from.pathname;
    }
    this.props.history.push(destination);
  };

  render() {
    const {
      successful_login,
      wrong_permissions,
      error_message,
      email,
      password,
      not_abilitato,
    } = this.state;

    return (
      <div style={styles.loginBackground}>
        <Row justify="center" align="middle">
          <Col>
            <img
              src={require("../Assets/logo-white.png")}
              style={styles.logoStyle}
              alt="logo"
            />
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[15, 15]}>
          {successful_login ? (
            <CustomResult
              icon="success"
              title="Bentornato!"
              subtitle="Ti sei autenticato con successo"
              button={{
                title: "Prosegui",
                route: () => this._destinationAfterSuccessLogin(),
                icon: <RightOutlined />,
              }}
            />
          ) : wrong_permissions ? (
            <CustomResult
              icon="info"
              title="Accesso negato"
              subtitle="Sembra che tu non sia un comune."
              button={{
                title: "Torna alla home",
                route: () => this.props.history.push("/"),
                icon: <HomeOutlined />,
              }}
            />
          ) : not_abilitato ? (
            <CustomResult
              icon="info"
              title="Accesso negato"
              subtitle="Il tuo comune non è abilitato, contattare l'amministratore di Garzone per l'abilitazione"
              button={{
                title: "Torna alla home",
                route: () => this.props.history.push("/"),
                icon: <HomeOutlined />,
              }}
            />
          ) : (
            <>
              <Col span={24}>
                <Title level={4} style={styles.title}>
                  Accedi al pannello di controllo del tuo comune
                </Title>
              </Col>
              {error_message !== "" && (
                <Col span={24}>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col span={12}>
                      <Alert
                        message={error_message}
                        type="error"
                        style={styles.alert}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col xs={24} sm={18} md={12}>
                <Form onSubmitCapture={async (e) => await this._doLogin(e)}>
                  <Row justify="center" align="middle" gutter={[15, 15]}>
                    <Col span={24}>
                      <Input
                        placeholder="Email"
                        id="email"
                        value={email}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </Col>
                    <Col span={24}>
                      <Input.Password
                        placeholder="Password"
                        id="password"
                        value={password}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        icon={<LoginOutlined />}
                      >
                        Accedi
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    justify="center"
                    align="middle"
                    gutter={[15, 15]}
                    style={styles.forgotPassword}
                  >
                    <Col span={24}>
                      <span style={{ color: Settings.colors.white }}>
                        Non ti ricordi la password? <br /> Nessun problema, ti
                        aiutiamo noi.{" "}
                      </span>
                      <a
                        onClick={() =>
                          this.props.history.push("/recupera-password")
                        }
                      >
                        Clicca qui
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
        <Loading loading={this.state.loading} />
      </div>
    );
  }
}

const styles = {
  loginBackground: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `url(${require("../Assets/background.png")})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "15px",
  },
  logoStyle: {
    height: 100,
    marginTop: 35,
    marginBottom: 35,
  },
  title: {
    color: Settings.colors.white,
    textAlign: "center",
  },
  forgotPassword: {
    textAlign: "center",
    color: "var(--white)",
  },
  alert: {
    marginBottom: "15px",
    alignSelf: "center",
  },
};

export default Login;
