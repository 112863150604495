import { combineReducers, createStore, applyMiddleware } from "redux";
import Logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";

import storage from 'redux-persist/lib/storage';
import AuthReducer from "../Reducers/AuthReducer";


const rootReducer = combineReducers({
    auth: AuthReducer
});
const persistConfig = {
    key: 'root',
    storage
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(Logger));
const persistor = persistStore(store);

export { store, persistor };