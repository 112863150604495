import React from 'react';
import Wrapper from '../Components/Wrapper';
import Label from '../Components/Label';
import { Divider } from 'antd';
import PageHeader from '../Components/PageHeader';

class Network extends React.Component {
  render() {
    return (
      <>
        <Wrapper>
          <PageHeader
            title='Network'
            description='Interagisci con la community dei negozi del comune.' />
          <Divider />
        </Wrapper>
      </>
    );
  }
}

export default Network;