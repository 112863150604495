import React from 'react';

// import ant design
import { Spin } from 'antd'

class Loading extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: false,
      message: 'Attendi...'
    }
  }

  componentDidMount(){
    this.setState({loading: this.props.loading})
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      this.setState({loading: this.props.loading})
      if(this.props.message){
        this.setState({message: this.props.message})
      }
    }
  }

  render(){
    return(
      <>
      {
        this.state.loading == true
        ?
        <div style={{display:'flex',flexDirection:'column', justifyContent:'center',zIndex:10000,position: 'fixed', width:'100%',height:'100%',left:0,top:0,background:'rgba(255,255,255,0.50)'}}>
          <Spin size="large" tip={this.state.message} />
        </div>
        :
        null
      }
      </>
    )
  }
}

export default Loading