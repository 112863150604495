import React from 'react';
import { get_negozio, get_negozi_comune } from '../Controllers/Negozi';
import { Table, Tag, Input, Space, Button, Drawer, Row, Col, Divider, Switch, List, notification } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import Wrapper from '../Components/Wrapper';
import Loading from "../Components/Loading";
import { connect } from "react-redux";

import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import DescriptionLabel from '../Components/DescriptionLabel';
import { aggiorna_negozio } from '../Controllers/Negozi';
import PageHeader from '../Components/PageHeader';

class Negozi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      negozi: [],

      searchText: '',
      searchedColumn: '',

      filteredInfo: null,
      sortedInfo: null,

      loaded: false,

      visible: false,
      selected_negozio: null,
      selected_index: null,
      save_disabled: true
    }
  }

  //#region Helpers Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
              </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  //#endregion

  //#region Helpers Filtri
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  }
  //#endregion

  //#region Helpers Drawer
  _showDrawer = () => this.setState({ visible: true });
  _onClose = () => this.setState({ visible: false });

  //#endregion

  //#region Helpers Notification
  _openEnableNotification = () => {
    console.log(this.state.selected_negozio)
    notification.destroy();
    notification.success({
      message: 'PERFETTO!',
      description:
        `Hai ${this.state.check ? 'abilitato' : 'disabilitato'} il negozio ${this.state.selected_negozio.nome_negozio}`,
      placement: 'topRight'
    });
  };

  _openErrorNotification = () => {
    notification.error({
      message: 'ERRORE',
      description:
        `Si è verificato un errore. Riprova!`,
      placement: 'topRight'
    });
  };
  //#endregion
  //#region Helpers Table
  _clearFiltri = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null
    })
  }

  _getColumns = () => {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    return [
      {
        title: "Nome Negozio",
        dataIndex: "nome_negozio",
        key: "nome_negozio",
        sorter: (a, b) => a.nome_negozio.localeCompare(b.nome_negozio),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('nome negozio', 'nome_negozio'),
        filteredValue: filteredInfo.nome_negozio || null,
        sortOrder: sortedInfo.columnKey === 'nome_negozio' && sortedInfo.order,
        width: 200
      },
      {
        title: "Referente",
        children: [
          {
            title: "Nome",
            dataIndex: "nome_referente",
            key: "nome_referente",
            sorter: (a, b) => a.nome_referente.localeCompare(b.nome_referente),
            sortDirections: ['descend', 'ascend'],
            ...this.getColumnSearchProps('nome referente', 'nome_referente'),
            filteredValue: filteredInfo.nome_referente || null,
            sortOrder: sortedInfo.columnKey === 'nome_referente' && sortedInfo.order,
            width: 150
          },
          {
            title: "Cognome",
            dataIndex: "cognome_referente",
            key: "cognome_referente",
            sorter: (a, b) => a.cognome_referente.localeCompare(b.cognome_referente),
            sortDirections: ['descend', 'ascend'],
            ...this.getColumnSearchProps('cognome referente', 'cognome_referente'),
            filteredValue: filteredInfo.cognome_referente || null,
            sortOrder: sortedInfo.columnKey === 'cognome_referente' && sortedInfo.order,
            width: 200
          },
          {
            title: "Telefono",
            dataIndex: "telefono_referente",
            ...this.getColumnSearchProps('telefono referente', 'telefono_referente'),
            filteredValue: filteredInfo.telefono || null,
            width: 150
          }]
      },
      {
        title: "E-Mail",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('e-mail', 'email'),
        filteredValue: filteredInfo.email || null,
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        width: 200
      },
      {
        title: "Categoria",
        dataIndex: "categoria",
        key: "categoria",
        sorter: (a, b) => a.categoria.localeCompare(b.categoria),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps('categoria', 'categoria'),
        filteredValue: filteredInfo.categoria || null,
        sortOrder: sortedInfo.columnKey === 'categoria' && sortedInfo.order,
        width: 200
      },
      {
        title: "Partita IVA",
        dataIndex: "partita_iva",
        key: "partita_iva",
        ...this.getColumnSearchProps('partita iva', 'partita_iva'),
        filteredValue: filteredInfo.partita_iva || null,
        width: 200
      },
      {
        title: 'Ubicazione',
        children: [{
          title: "Città",
          dataIndex: "citta",
          key: "citta",
          sorter: (a, b) => a.citta.localeCompare(b.citta),
          sortDirections: ['descend', 'ascend'],
          ...this.getColumnSearchProps('città', 'citta'),
          filteredValue: filteredInfo.citta || null,
          sortOrder: sortedInfo.columnKey === 'citta' && sortedInfo.order,
          width: 200
        },
        {
          title: "Indirizzo",
          dataIndex: "indirizzo",
          key: "indirizzo",
          sorter: (a, b) => a.indirizzo.localeCompare(b.indirizzo),
          sortDirections: ['descend', 'ascend'],
          ...this.getColumnSearchProps('indirizzo', 'indirizzo'),
          filteredValue: filteredInfo.indirizzo || null,
          sortOrder: sortedInfo.columnKey === 'indirizzo' && sortedInfo.order,
          width: 200
        },
        {
          title: "Civico",
          dataIndex: "civico",
          key: "civico",
          ...this.getColumnSearchProps('civico', 'civico'),
          filteredValue: filteredInfo.civico || null,
          width: 120
        },
        {
          title: "Provincia",
          dataIndex: "provincia",
          key: "provincia",
          sorter: (a, b) => a.provincia.localeCompare(b.provincia),
          sortDirections: ['descend', 'ascend'],
          ...this.getColumnSearchProps('provincia', 'provincia'),
          filteredValue: filteredInfo.provincia || null,
          sortOrder: sortedInfo.columnKey === 'provincia' && sortedInfo.order,
          width: 200
        },
        {
          title: "CAP",
          dataIndex: "cap",
          key: "cap",
          ...this.getColumnSearchProps('cap', 'cap'),
          filteredValue: filteredInfo.cap || null,
          width: 100
        }]
      },
      {
        title: "Abilitato",
        dataIndex: "abilitato",
        key: "abilitato",
        render: value => value ? <Tag color="green">Sì</Tag> : <Tag color="red">No</Tag>,
        fixed: 'right',
        filters: [
          { text: 'Sì', value: 1 },
          { text: 'No', value: 0 },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.abilitato == value,
        filteredValue: filteredInfo.abilitato || null,
        ellipsis: true,
        align: 'center',
        width: 100
      },
      {
        title: '',
        dataIndex: 'settings',
        key: 'settings',
        render: (text, record, index) =>
          <Row justify='space-around' align='middle'>
            <EditOutlined style={{ color: '#C0C1C2', fontSize: 18 }}
              onClick={async () => {
                this.setState({ loaded: false });
                const { data } = await get_negozio(record.id);
                this.setState({
                  selected_negozio: data,
                  selected_index: data.id,
                  visible: true,
                  loaded: true
                })
              }} />
          </Row>,
        fixed: 'right',
        align: 'right',
        width: 50
      }
    ]
  };

  //Ricerca
  getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Cerca ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Cerca
                    </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
                    </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.filteredInfo == null
        ? text
        :
        this.state.searchedColumn === dataIndex && this.state.filteredInfo[dataIndex] != null ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
            text
          ),
  });

  //Ricerca
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Ricerca
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    console.log(this.state.filteredInfo, this.state.sortedInfo);
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  //#endregion

  _loader = async () => {
    let user = this.props.auth.user;
    let negozi = await get_negozi_comune(user.id_comune);
    this.setState({
      negozi: negozi.data,
      loaded: true
    });
  }

  //#region LifeCycle Component
  componentDidMount() {
    this._loader();
  }

  render() {
    const { selected_negozio, selected_index, loaded } = this.state;
    return (<><Wrapper>
      <PageHeader
        title='Negozi'
        description='Controlla e abilita i negozi ad utilizzare Garzone.' />
      <Divider />
      <Row align='middle' justify='end' gutter={[15, 15]} style={{ marginBottom: '15px' }}>
        <Col>
          <Button
            onClick={this._clearFiltri}
            size='large'
          >
            Reset Filtri
            </Button>
        </Col>
      </Row>
      <Table
        bordered
        dataSource={this.state.negozi}
        onChange={this._handleChange}
        columns={this._getColumns()}
        size="small"
        scroll={{ x: 1500 }}
      />
      {selected_negozio
        ? <Drawer
          destroyOnClose
          placement="right"
          onClose={() => this.setState({ visible: false, selected_negozio: null, selected_index: null })}
          footer={
            <Row justify='end'>
              <Button size='large' onClick={() => this.setState({ visible: false })}>Chiudi</Button>
            </Row>
          }
          visible={this.state.visible}
          width="100%"
          closable={false}
          title={
            <Row align="middle" justify="space-between">
              <Col>
                <h1 style={{ marginBottom: 0 }}>{selected_negozio.nome_negozio}</h1>
              </Col>
              {window.innerWidth > 900 ? null : <Divider />}
              <Col>
                <span>Abilitato: </span>
                <Switch defaultChecked={selected_negozio.abilitato} onChange={async (checked) => {
                  try {
                    let negozi = [...this.state.negozi];
                    this.setState({ loaded: false });
                    await aggiorna_negozio(selected_negozio.id, checked);
                    notification.success({
                      message: 'Congratulazioni!',
                      description:
                        `Hai ${checked ? 'abilitato' : 'disabilitato'} il negozio ${selected_negozio.nome_negozio}!`
                    });
                    negozi
                      .filter(n => n.id === selected_index)
                      .map(n => n.abilitato = checked);
                    this.setState({
                      loaded: true,
                      negozi: negozi
                    })
                  }
                  catch (err) {
                    notification.error({
                      message: 'Errore nella richiesta',
                      description:
                        'Non è stato possibile completare la richiesta. Riprovare più tardi o contattare l\'assistenza'
                    })
                  }

                }} />
              </Col>
            </Row>
          }
        >
          <p >
            Referente
              </p>
          <Row>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Nome" content={selected_negozio.nome_referente} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Cognome" content={selected_negozio.cognome_referente} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Telefono" content={selected_negozio.telefono_referente} goTo={"tel:" + selected_negozio.telefono_referente} />
            </Col>
          </Row>
          <Divider />
          <p>Dettagli Negozio</p>
          <Row>
            <Col xs={24} md={24}>
              <DescriptionLabel title="Descrizione" content={selected_negozio.descrizione} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Categoria" content={selected_negozio.categoria} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Partita IVA" content={selected_negozio.partita_iva} />
            </Col>
            <Col xs={24} md={24}>
              <DescriptionLabel title="Orari di Apertura" content={selected_negozio.orari_apertura} />
            </Col>
          </Row>

          <Divider />
          <p>Contatti</p>
          <Row>
            <Col xs={24} md={24}>
              <DescriptionLabel
                title="Indirizzo"
                content={selected_negozio.indirizzo}
                goTo={"https://www.google.com/maps/search/?api=1&query=" + selected_negozio.latitudine + "," + selected_negozio.longitudine} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Provincia" content={selected_negozio.provincia} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="CAP" content={selected_negozio.cap} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Latitudine" content={selected_negozio.latitudine} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Longitudine" content={selected_negozio.longitudine} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Telefono" content={selected_negozio.telefono} goTo={"tel:" + selected_negozio.telefono} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="E-Mail" content={selected_negozio.email} goTo={"mailto:" + selected_negozio.email} />
            </Col>
          </Row>

          <Divider />
          <p>Social</p>
          <Row>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Sito web" content={selected_negozio.sito_web} goTo={selected_negozio.sito_web} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Whatsapp" content={selected_negozio.whatsapp} goTo={selected_negozio.whatsapp} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Instagram" content={selected_negozio.instagram} goTo={selected_negozio.instagram} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Facebook" content={selected_negozio.facebook} goTo={selected_negozio.facebook} />
            </Col>
          </Row>
          <Divider />
          <p>Pagamenti</p>
          <Row>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Bancomat" content={selected_negozio.bancomat ? "Sì" : "No"} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Carta" content={selected_negozio.carta ? "Sì" : "No"} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Contante" content={selected_negozio.contante ? "Sì" : "No"} />
            </Col>
            <Col xs={24} md={12}>
              <DescriptionLabel title="Ticket" content={selected_negozio.ticket} />
            </Col>
          </Row>
          <Divider />
          <p>Consegna</p>
          <Row>
            <Col xs={24} md={24}>
              <DescriptionLabel title="Modalità Consegna" content={selected_negozio.modalita_consegna} />
            </Col>
            <Col xs={24} md={24}>
              <DescriptionLabel title="Modulo Prenotazioni" content={selected_negozio.modulo_prenotazioni} />
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col xs={24} md={12}>
              <p>Categorie Prodotti</p>
              <List
                itemLayout="horizontal"
                dataSource={selected_negozio.categorie_prodotti ? selected_negozio.categorie_prodotti.split(',') : []}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={<span>{item}</span>}
                    />
                  </List.Item>
                )}
                style={{ paddingRight: '10px' }}
              />
            </Col>
            <Col xs={24} md={12}>
              <p>Categorie Servizi</p>
              <List
                itemLayout="horizontal"
                dataSource={selected_negozio.categorie_servizi ? selected_negozio.categorie_servizi.split(',') : []}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={<span>{item}</span>}
                    />
                  </List.Item>
                )}
                style={{ paddingRight: '10px' }}
              />
            </Col>

          </Row>

          <Divider />

          <p>Info Aggiuntive</p>
          <Row>
            <Col xs={24} md={8}>
              <DescriptionLabel title="Accettazione Privacy" content={selected_negozio.privacy_condizioni ? 'Sì' : 'No'} />
            </Col>
            <Col xs={24} md={8}>
              <DescriptionLabel title="Abilitazione" content={selected_negozio.abilitato ? 'Sì' : 'No'} />
            </Col>
            <Col xs={24} md={8}>
              <DescriptionLabel title="Bloccato" content={selected_negozio.bloccato ? 'Sì' : 'No'} />
            </Col>
          </Row>
        </Drawer>
        : null
      }

    </Wrapper>
      <Loading loading={!loaded} />
    </>
    )
  }
  //#endregion
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Negozi);